<template>
  <div class="container flex">
    <img :src="image">
  </div>
</template>

<script>
export default {
  name: 'Device',
  data() {
    return {
      ImageMap: {
        'en': 'https://esim.qn.triproaming.cn/devicesimage/device_app_en.png',
        'zh-tw':'https://esim.qn.triproaming.cn/devicesimage/device_app_zh-tw.png',
        'zh-cn': 'https://esim.qn.triproaming.cn/devicesimage/device_app_zh-cn.png',
      }
    }
  },
  computed:{
    image(){
      return this.ImageMap[this.$i18n.locale]
    }
  }
}
</script>

<style scoped></style>